import React from 'react'
import './header.scss'
import loadable from '@loadable/component'
import Marked from 'react-markdown'

const Fade = loadable(() => import('../transition/Fade'))

const Header = ({ data }) => {
  return (
    <div className="service-header-container d-lg-flex justify-content-between" id="common-pl">
      <div className="title-container first-flex-column ">
        <p className="sub-title">More About</p>
        <Fade timeout={500}>
          <h1>{data.title}</h1>
        </Fade>
        <Fade timeout={1000}>
          <p className="title-dec">
            <Marked parserOptions={{ commonmark: true}} source={data.bannerDescription} />
          </p>
        </Fade>
      </div>
      <Fade timeout={1000}>
        <div className="img-container second-flex-column">
          {data.bannerImages.map(({ image, alt, title }, index) => (
            <img
              className={`service-header-img image_${index}`}
              src={image}
              alt={alt}
              key={index}
            />
          ))}
        </div>
      </Fade>
      <div className="bg-text-container d-none d-lg-block">
        <img
          src="https://res.cloudinary.com/dhuii7xg2/image/upload/c_scale,f_auto,q_auto,w_auto/v1614325548/SERVIC_xmtvji.png"
          alt="Service Text"
          className="img-fluid"
        />
      </div>
    </div>
  )
}

export default Header
